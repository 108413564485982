import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from '../Dashboard'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../../api'
import { get } from 'lodash'
import Layout from '../../../components/Layout'
import OutgoingPaymentsModal from '../../../components/Modal/OutgoingPymentsModal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Empty, DatePicker } from 'antd'
const { RangePicker } = DatePicker

export default function Tab1() {
	const navigation = useNavigate()
	const { getMe } = useSelector((state) => state.main)
	const dateFormat = 'YYYY-MM-DD'

	const OutgoingPaymentsModalRef = useRef()

	const [isLoading, setIsLoading] = useState({
		loadingDebitCredit: false,
		loadingPurchases: false,
		loadingSold: false,
	})
	const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
	const [data, setData] = useState({
		data: [],
	})
	const [pageSold, setPageSold] = useState(0)
	const [pagePurchased, setPagePurchased] = useState(0)
	const [purchasedData, setPurchasedData] = useState({
		data: [],
		currentPage: 0,
	})
	const [dailyAccountStats, setDailyAccountStats] = useState([])
	const [balanceMoney, setBalanceMoney] = useState([])
	const [balanceLoading, setBalanceLoading] = useState(false)

	const fetchDailyAccountStats = async () => {
		setIsLoading((prev) => ({ ...prev, loadingDebitCredit: true }))
		const url =
			get(getMe, 'Department2.Name', '') === 'Sotuv'
				? `SQLQueries(%27getDebitCreditSum%27)/List?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&account1='${get(getMe, 'U_CardAccount', '')}'&account2='${get(getMe, 'U_CashAccount', '')}'&account3='${get(getMe, 'U_CashUzsAccount', '')}'&account4='${get(getMe, 'U_TerminalAccount', '')}'&account5='${get(getMe, 'U_TransAccount', '')}'`
				: `SQLQueries(%27getDebitCreditSum%27)/List?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'`

		api(url)
			.then((res) => {
				setDailyAccountStats(JSON.parse(res.data).value[0])
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingDebitCredit: false }))
			})
	}

	const sold = () => {
		setIsLoading((prev) => ({ ...prev, loadingSold: true }))
		customFuncsApi(
			`xsSql/getLastInitialPayments?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&%24skip=${pageSold}${get(getMe, 'Department2.Name', '') === 'Sotuv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingSold: false }))
			})
	}

	const BalanceMoney = async () => {
		setBalanceLoading(true)
		let url =
			get(getMe, 'Department2.Name', '') === 'Sotuv'
				? `ChartOfAccounts?$filter=Code eq '${get(getMe, 'U_CardAccount', '')}' or Code eq '${get(getMe, 'U_CashAccount', '')}' or Code eq '${get(getMe, 'U_CashUzsAccount', '')}' or Code eq '${get(getMe, 'U_TerminalAccount', '')}' or Code eq '${get(getMe, 'U_TransAccount', '')}' and  ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`
				: `ChartOfAccounts?$filter=startswith(Code, '50') and ExternalCode eq '1'&$select=Code,Name,Balance,Balance_syscurr,Balance_FrgnCurr,AcctCurrency`

		const data = await api
			.get(url, {
				headers: {
					Prefer: 'odata.maxpagesize=10000',
				},
			})
			.then((res) => JSON.parse(res.data).value)
		setBalanceMoney(data)
		setBalanceLoading(false)
		console.log(data)
	}

	useEffect(() => {
		BalanceMoney()
	}, [])

	const purchased = () => {
		setIsLoading((prev) => ({ ...prev, loadingPurchases: true }))
		customFuncsApi(
			`xsSql/getLastPurchases?date1='${startDate + ' 00:00:00.000Z'}'&date2='${endDate + ' 00:00:00.000Z'}'&$skip=${pagePurchased}${get(getMe, 'Department2.Name', '') === 'Sotuv' ? `&WhsCode='${get(getMe, 'U_Warehouse', '')}'` : ''}`,
			{
				headers: {
					Prefer: 'odata.maxpagesize=10',
				},
			},
		)
			.then((res) => {
				const resData = res.data.value
				setPurchasedData({ data: [...resData] })
			})
			.catch((err) => {
				// errorRef.current?.open(
				//   get(JSON.parse(err.response.data), 'error.message', ''),
				// )
			})
			.finally(() => {
				setIsLoading((prev) => ({ ...prev, loadingPurchases: false }))
			})
	}

	useEffect(() => {
		fetchDailyAccountStats()
		console.log(getMe)
	}, [startDate, endDate])

	useEffect(() => {
		purchased()
	}, [startDate, endDate, pagePurchased])

	useEffect(() => {
		sold()
	}, [pageSold, startDate, endDate])

	const newDatas = (table) => {
		if (table === 'sold') {
			if (data.data.length < 10) {
				alert('boshqa malumot yoq')
			} else setPageSold((prev) => prev + 10)
		} else {
			if (purchasedData.data.length < 10) {
				alert('boshqa malumot yoq')
			} else setPagePurchased((prev) => prev + 10)
		}
	}

	const oldData = (table) => {
		if (table === 'sold' && pageSold <= 0) {
			alert('boshqa malumot yoq')
		} else if (table === 'purchased' && pagePurchased <= 0) {
			alert('boshqa malumot yoq')
		} else {
			table === 'sold'
				? setPageSold((prev) => prev - 10)
				: setPagePurchased((prev) => prev - 10)
		}
	}

	const handleStartDateChange = (date) => {
		setStartDate(date ? date.format('YYYY-MM-DD') : null)
	}

	const handleEndDateChange = (date) => {
		setEndDate(date ? date.format('YYYY-MM-DD') : null)
	}
	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			const start = dates[0]
			const end = dates[1]

			handleStartDateChange(start)
			handleEndDateChange(end)
		} else {
			console.log('Clear')
		}
	}

	return (
		<DashboardStyle>
			<div className="container">
				<p className="productTitle mb-10">Главная страница</p>
				{/* <div className="flex gap-4">
        <div>
          <p className={'font-medium text-zinc-600 text-sm mb-1'}>
            Период с
          </p>
          <input
            className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md'}
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div>
          <p className={'font-medium text-zinc-600 text-sm mb-1'}>по</p>
          <input
            className={'border-[1px] border-[#DFE2E9"] p-1 rounded-md '}
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
      </div> */}
				{/* <div className="flex items-center">
        <DatePicker
          value={startDate ? moment(startDate, 'YYYY-MM-DD') : null}
          onChange={handleStartDateChange}
          placeholder="Start Date"
          className="w-36 p-2 border border-gray-300 rounded text-sm placeholder-gray-500"
        />
        <span className="mx-2">по</span>
        <DatePicker
          value={endDate ? moment(endDate, 'YYYY-MM-DD') : null}
          onChange={handleEndDateChange}
          placeholder="End Date"
          className="w-36 p-2 border border-gray-300 rounded text-sm placeholder-gray-500"
        />
      </div> */}
				<RangePicker
					value={[
						startDate ? dayjs(startDate, dateFormat) : null,
						endDate ? dayjs(endDate, dateFormat) : null,
					]}
					format={dateFormat}
					onChange={onRangeChange}
				/>
				<div className="invoice-container ">
					{isLoading.loadingDebitCredit || balanceLoading ? (
						<ClipLoader
							loading={true}
							size={20}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="flex  gap-2 items-start">
								<div className="invoice-card min-w-[300px]">
									<div className="type income w-fit">
										Сумма приходов за сегодня
									</div>
									<p className="mt-4 text-xl font-bold text text-zinc-900">
										{new Intl.NumberFormat('fr-FR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})
											.format(dailyAccountStats?.DebitSum)
											.replace(',', '.') || 0}{' '}
										{/* {(dailyAccountStats?.DebitSum
                   &&
                    dailyAccountStats?.DebitSum.toFixed(2)) ||
                    0}{' '} */}
										USD
									</p>
								</div>
								<div
									className="invoice-card min-w-[300px]"
									onClick={() => {
										OutgoingPaymentsModalRef.current?.open({
											startDate: startDate,
											endDate: endDate,
										})
									}}
								>
									<div className="type withdraw w-fit">
										Сумма расходов за сегодня
									</div>
									<p className="mt-4 text-xl font-bold text text-zinc-900">
										{new Intl.NumberFormat('fr-FR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})
											.format(dailyAccountStats?.CreditSum)
											.replace(',', '.') || 0}{' '}
										USD
									</p>
								</div>
								<div className="max-h-[30vh] overflow-y-scroll">
									<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
										<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
											<th scope="col" className="px-6 py-4">
												Счет
											</th>
											<th scope="col" className="px-6 py-4">
												Название
											</th>
											<th scope="col" className="px-6 py-4">
												Баланс
											</th>
											<th scope="col" className="px-6 py-4">
												Валюта
											</th>
										</thead>
										<tbody>
											{balanceMoney.length ? (
												balanceMoney.map((v, i) => {
													return (
														<tr
															className="bg-white border-b  hover:bg-gray-50 "
															key={i}
														>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.Code}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.Name}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.AcctCurrency === 'UZS'
																	? new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(v.Balance_syscurr)
																			.replace(',', '.')
																	: new Intl.NumberFormat('fr-FR', {
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		})
																			.format(v.Balance)
																			.replace(',', '.')}
															</td>
															<td
																scope="row"
																className="px-6 py-4 font-medium text-gray-900 "
															>
																{v.AcctCurrency}
															</td>
														</tr>
													)
												})
											) : (
												<p className="px-6 py-4 font-medium text-gray-900 ">
													Счет нету
												</p>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</>
					)}
				</div>
				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						Продано
					</h4>
					{isLoading.loadingSold ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Товар
										</th>
										<th scope="col" className="px-6 py-4">
											Дата продажи
										</th>
										<th scope="col" className="px-6 py-4">
											Взнос
										</th>
										<th scope="col" className="px-6 py-4">
											Итого
										</th>
									</tr>
								</thead>
								<tbody>
									{data.data.length > 0 ? (
										data.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.SumApplied)
															.replace(',', '.') || 0}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.DocTotal)
															.replace(',', '.') || 0}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description="Нет информации!"
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}

					<div className="topCard">
						<div className="flex">
							<Button
								disabled={pageSold === 0}
								className={'btn'}
								onClick={() => oldData('sold')}
								btnStyle={{ marginRight: 10 }}
							>
								{'<'}
							</Button>
							<Button
								disabled={data.data.length < 10}
								className={'btn'}
								onClick={() => newDatas('sold')}
							>
								{'>'}
							</Button>
						</div>
					</div>
				</div>
				<div>
					<h4 className={'font-bold text-lg text-center mt-10 mb-4'}>
						Куплено
					</h4>
					{isLoading.loadingPurchases ? (
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<ClipLoader
								loading={true}
								size={20}
								aria-label="Loading Spinner"
								className={'loader'}
								data-testid="loader"
							/>
						</div>
					) : (
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Товар
										</th>
										<th scope="col" className="px-6 py-4">
											Дата покупки
										</th>
										<th scope="col" className="px-6 py-4">
											Итого
										</th>
										<th scope="col" className="px-6 py-4">
											Покупатель
										</th>
										<th scope="col" className="px-6 py-4">
											Количество
										</th>
									</tr>
								</thead>
								<tbody>
									{purchasedData.data.length > 0 ? (
										purchasedData.data.map((v, i) => {
											return (
												<tr
													key={i}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{v.Dscription}
													</td>
													<td className="px-6 py-4">
														{v.DocDate &&
															moment(v.DocDate).format('DD-MM-YYYY')}
													</td>
													<td className="px-6 py-4">
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(v.DocTotal)
															.replace(',', '.') || 0}
													</td>
													<td className="px-6 py-4">{v.CardName}</td>
													<td className="px-6 py-4">
														{Math.round(v.Quantity)}
													</td>
												</tr>
											)
										})
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description="Нет информации!"
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}

					<div className="topCard">
						<div className="flex">
							<Button
								disabled={pagePurchased === 0}
								className={'btn'}
								onClick={() => oldData('purchased')}
								btnStyle={{ marginRight: 10 }}
							>
								{'<'}
							</Button>
							<Button
								disabled={purchasedData.data.length < 10}
								className={'btn'}
								onClick={() => newDatas('purchased')}
							>
								{'>'}
							</Button>
						</div>
					</div>
				</div>
				<OutgoingPaymentsModal
					getRef={(r) => {
						OutgoingPaymentsModalRef.current = r
					}}
				/>
			</div>
		</DashboardStyle>
	)
}
