import React from 'react'
import styled from 'styled-components'

const ConfirmModal = ({ show, message, onConfirm, onCancel, data }) => {
	if (!show) {
		return null
	}

	return (
		<Modal className="modal-backdrop">
			<div className="modal">
				<h2 className={'text-[#171717E2] font-medium text-xl mb-8'}>
					Пожалуйста, подтвердите детали ниже:
				</h2>
				<div className={'max-h-[400px] overflow-y-scroll scroll-visible'}>
					{data.map((item) => (
						<div>
							<p>
								{item.name}{' '}
								<span className={'text-blue-700'}>{item.value}</span>
							</p>
						</div>
					))}
				</div>
				<p>{message}</p>
				<div className={'flex justify-end gap-2'}>
					<button className="modal-button" onClick={onConfirm}>
						Подтвердить
					</button>
					<button className="modal-button" onClick={onCancel}>
						Отменить
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmModal

const Modal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(12, 12, 12, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;

	.modal {
		background: white;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		max-width: 500px;
	}

	.modal h2 {
		margin-top: 0;
		text-align: center;
	}

	.modal p {
		margin: 20px 0;
	}

	.modal-button {
		padding: 10px 20px;
		border: none;
		border-radius: 5px;
		background-color: #007bff;
		color: white;
		cursor: pointer;
	}

	.modal-button:hover {
		background-color: #0056b3;
	}
`
