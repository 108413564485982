import React, { useEffect, useRef, useState } from 'react'
// import CreateUserStyle from '../CreateUser/CreateUserStyle'
import Modal from 'react-modal'

import { get } from 'lodash'
import api, { customFuncsApi } from '../../../api'
import { useSelector } from 'react-redux'
import { ErrorModal } from '../../../components/Modal'
import moment from 'moment'
import { Empty } from 'antd'
import ClipLoader from 'react-spinners/ClipLoader'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh', // To make modal content scrollable if it exceeds viewport height
		// overflowY: 'auto',
	},
	overlay: {
		background: '#00000099',
	},
}

const InfoByWhsModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	isLoading = false,
	onUpdated = () => {},
}) => {
	const { getMe } = useSelector((state) => state.main)
	const errorModalRef = useRef()
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])
	const [name, setName] = useState('')

	useEffect(() => {
		const ref = {
			open: (data) => {
				getInfoByWhs(data)
				setIsOpenModal(true)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])

	const getInfoByWhs = (num) => {
		setLoading(true)
		customFuncsApi
			.get(`xsSql/getDunningProblemStatsByWhs?U_Score=${num}`)
			.then((res) => {
				setData(res.data.value)
				setName(res.data.value[0].Descr)
			})
			.catch((err) => {
				errorModalRef.current?.open(JSON.stringify(err.response.message))
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			{/* <CreateUserStyle> */}
			<div className="card">
				<p className="text-center font-bold ">{name}</p>
				{loading ? (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<ClipLoader
							loading={true}
							size={20}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					</div>
				) : data ? (
					<div className=" overflow-x-auto mb-8 mt-10">
						<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
								<tr>
									<th scope="col" className="px-6 py-4">
										Имя склада
									</th>
									<th scope="col" className="px-6 py-4">
										Количество
									</th>
								</tr>
							</thead>
							<tbody>
								{data.length > 0 ? (
									data.map((v, i) => {
										return (
											<tr
												key={i}
												// onClick={() => viewItem(v)}
												className="bg-white border-b  hover:bg-gray-50 "
											>
												{' '}
												<td className="px-6 py-4">{get(v, 'WhsName', '-')}</td>
												<td className="px-6 py-4">{get(v, 'Count', '-')}</td>
											</tr>
										)
									})
								) : (
									<tr>
										<td colSpan="10">
											<Empty
												description="Нет информации!"
												image={Empty.PRESENTED_IMAGE_SIMPLE}
											/>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				) : (
					''
				)}
			</div>
			{/* </CreateUserStyle> */}

			<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
		</Modal>
	)
}

export default InfoByWhsModal
