import Layout from '../../components/Layout'
import React, { useEffect, useRef, useState } from 'react'
import { customFuncsApi } from '../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import { get } from 'lodash'
import Button from '../../components/Button'
import { ErrorModal, SuccessModal, WarningModal } from '../../components/Modal'
import { roundCurrency } from '../../utils'
import SmsStyle from './SmsStyle'
import { Empty } from 'antd';
import formatPhoneNumber from '../../helpers/PhoneWithSpaces'

const SMSPage = () => {
	const errorRef = useRef()
	const successModalRef = useRef()
	const warningRef = useRef()

	const [debtorsData, setDebtorsData] = useState([])
	const [getLoading, setGetLoading] = useState(false)
	const [docEntries, setDocEntries] = useState([])
	const [sendLoading, setSendLoading] = useState(false)
	const [debtors, setDebtors] = useState('')
	const [comingDebtors, setComingDebtors] = useState('')
	const [form, setForm] = useState({
		cardNumber: '',
		cardHolder: '',
		currencyRate: '',
	})
	const [typeDebtors, setTypeDebtors] = useState('past')
	const [selectedDay, setSelectedDay] = useState('')
	const [telephoneNumber, setTelephoneNumber] = useState('')
	const [filteredDebtors, setFilteredDebtors] = useState([])

	const GetDebtors = () => {
		setGetLoading(true)
		customFuncsApi
			.get('/XsSql/getDebtsForSmsSending', {
				params: {
					showComingDebtsOnly: typeDebtors === 'comming' ? true : undefined,
					showDebtsOnly: typeDebtors === 'past' ? true : undefined,
				},
				headers: {
					Prefer: 'odata.maxpagesize=9000',
				},
			})
			.then((res) => {
				setDebtorsData(res.data.value)
				setFilteredDebtors(res.data.value)
				const allEntries = []
				res.data.value.forEach((v) => {
					if (v.DocEntry) {
						allEntries.push(v.DocEntry)
					}
				})
				setDocEntries(allEntries)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setGetLoading(false)
			})
	}

	useEffect(() => {
		GetDebtors()
	}, [typeDebtors])

	const SendSMS = () => {
		setSendLoading(true)
		customFuncsApi
			.post('/sendSmssToDebtors', {
				...form,
				docEntries,
			})
			.then((res) => {
				successModalRef.current?.open('Успешно отправлено')
			})
			.catch((err) => {
				errorRef.current?.open('Произошла ошибка')
			})
			.finally(() => {
				setSendLoading(false)
			})
	}

	const handleCheck = (docEntry) => {
		const newDocEntries = docEntries
		if (newDocEntries.includes(docEntry))
			newDocEntries.splice(newDocEntries.indexOf(docEntry), 1)
		else newDocEntries.push(docEntry)

		setDocEntries([...newDocEntries])
	}

	const handleChangeAll = () => {
		if (docEntries.length !== filteredDebtors.length) {
			const allDocEntries = []
			filteredDebtors.forEach((v) => {
				if (v.DocEntry) {
					allDocEntries.push(v.DocEntry)
				}
			})
			setDocEntries(allDocEntries)
		} else {
			setDocEntries([])
		}
	}

	useEffect(() => {
		let data = debtorsData
		if (selectedDay) {
			data = data.filter((item) => item.DueDay === +selectedDay)
		}
		if (telephoneNumber) {
			data = data.filter(
				(item) =>
					item.U_Telephone?.includes(telephoneNumber) ||
					item.Phone1?.includes(telephoneNumber) ||
					item.Phone2?.includes(telephoneNumber),
			)
		}
		const newEntries = []
		newEntries.push(...data.map((v) => v.DocEntry))
		setDocEntries(newEntries)

		setFilteredDebtors(data)
	}, [selectedDay, telephoneNumber])

	return (
		<Layout>
			<SmsStyle>
				<div className="p-16">
					<div className="shadow-md p-4">
						<div>
							<p className="font-medium text-[22px] mb-3">Фильтр</p>
							<div className={'flex gap-4 mb-4'}>
								<div className="day-picker-container">
									<label
										className={
											'font-medium text-zinc-600 text-[14px] mb-1 flex gap-2 flex flex-col'
										}
									>
										Дата платежа
										<select
											className={
												'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
											}
											id="day-select"
											value={selectedDay}
											onChange={(event) => setSelectedDay(event.target.value)}
										>
											<option value="">Выберите дату</option>
											{[...Array(31)].map((_, i) => (
												<option key={i + 1} value={i + 1}>
													{i + 1}
												</option>
											))}
										</select>
									</label>
								</div>
								<label
									className={
										'font-medium text-zinc-600 text-[14px] mb-1 flex gap-2 flex flex-col'
									}
								>
									Номер телефона
									<input
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										value={telephoneNumber}
										onChange={(event) => setTelephoneNumber(event.target.value)}
									/>
								</label>
							</div>
						</div>
						<div>
							<p className="font-medium text-[22px] mb-3">Тип должников</p>
							<div className={'flex gap-4 mb-4'}>
								<label
									className={
										'font-medium text-zinc-600 text-[14px] mb-1 flex gap-2 items-center'
									}
								>
									Прошедшие
									<input
										checked={typeDebtors === 'past'}
										type={'checkbox'}
										onChange={() => setTypeDebtors('past')}
									/>
								</label>
								<label
									className={
										'font-medium text-zinc-600 text-[14px] mb-1 flex gap-2 items-center'
									}
								>
									Приходящие
									<input
										checked={typeDebtors === 'comming'}
										type={'checkbox'}
										onChange={() => setTypeDebtors('comming')}
									/>
								</label>
							</div>
						</div>
						<div className="flex gap-3 mb-8">
							<input
								placeholder="Номер карты"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(e) =>
									setForm((prevState) => ({
										...prevState,
										cardNumber: e.target.value,
									}))
								}
							/>
							<input
								placeholder="Название карты"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(e) =>
									setForm((prevState) => ({
										...prevState,
										cardHolder: e.target.value,
									}))
								}
							/>
							<input
								placeholder="Курс доллара"
								className={
									'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
								}
								onChange={(e) =>
									setForm((prevState) => ({
										...prevState,
										currencyRate: e.target.value,
									}))
								}
							/>
							<Button
								disabled={!docEntries.length}
								isLoading={sendLoading}
								onClick={SendSMS}
							>
								Отправить
							</Button>
						</div>
						<div className="min-h-[50vh] flex justify-center w-full">
							{getLoading ? (
								<ClipLoader
									loading={true}
									size={35}
									aria-label="Loading Spinner"
									className={'loader'}
									data-testid="loader"
								/>
							) : (
								<div className="overflow-y-auto w-full">
									<table className="w-full text-sm text-left  text-gray-700 ">
										<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
											<tr>
												<th scope="col" className="px-6 py-4">
													<input
														onChange={handleChangeAll}
														checked={
															docEntries.length === filteredDebtors.length
														}
														type={'checkbox'}
													/>
												</th>
												<th scope="col" className="px-6 py-4">
													Код клиента
												</th>
												<th scope="col" className="px-6 py-4">
													Имя Клиента
												</th>
												<th scope="col" className="px-6 py-4">
													Товар
												</th>
												<th scope="col" className="px-6 py-4">
													Номер телефона
												</th>
												<th scope="col" className="px-6 py-4">
													Сумма
												</th>
												<th scope="col" className="px-6 py-4">
													Сумма задолженности
												</th>
												<th scope="col" className="px-6 py-4">
													Сумма задолженности будущее
												</th>
											</tr>
										</thead>

										<tbody>
											{filteredDebtors.length > 0 ? (
												filteredDebtors.map((v, i) => {
													return (
														<tr
															key={v.DocEntry}
															className="bg-white border-b  hover:bg-gray-50"
														>
															<td className="px-6 py-4 font-medium text-gray-900 ">
																<input
																	className="cursor-pointer"
																	type="checkbox"
																	checked={docEntries.includes(
																		get(v, 'DocEntry', ''),
																	)}
																	onChange={() => {
																		handleCheck(get(v, 'DocEntry', ''))
																	}}
																></input>
															</td>
															<td className="px-6 py-4 font-medium text-gray-900 ">
																{get(v, 'CardCode', '-')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'CardName', '-')}
															</td>
															<td className="px-6 py-4">
																{get(v, 'Dscription', '-')}
															</td>
															<td className="px-6 py-4">
																{formatPhoneNumber(get(v, 'U_Telephone', '-'))}{' '}
																{get(v, 'Phone1', '-')} {get(v, 'Phone2', '-')}
															</td>
															<td className="px-6 py-4">
																{new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(
																		roundCurrency(get(v, 'DocTotal', '-')),
																	)
																	.replace(',', '.')}
															</td>
															<td className="px-6 py-4">
																{new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(roundCurrency(get(v, 'Debt', '-')))
																	.replace(',', '.')}
															</td>
															<td className="px-6 py-4">
																{new Intl.NumberFormat('fr-FR', {
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																})
																	.format(
																		roundCurrency(get(v, 'ComingDebt', '-')),
																	)
																	.replace(',', '.')}
															</td>
														</tr>
													)
												})
											) : (
												<tr >
												<td colSpan="10" >
													<Empty description="Нет информации!" image={Empty.PRESENTED_IMAGE_SIMPLE} />
												</td>
											</tr>												)}
										</tbody>
									</table>
								</div>
							)}
						</div>
					</div>
				</div>
			</SmsStyle>
			<>
				<ErrorModal
					getRef={(r) => (errorRef.current = r)}
					onConfirm={() => errorRef.current?.close()}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
				<WarningModal
					getRef={(r) => (warningRef.current = r)}
					onConfirm={() => warningRef.current?.close()}
				/>
			</>
		</Layout>
	)
}

export default SMSPage
