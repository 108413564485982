import React, { useEffect, useRef, useState } from 'react'
import AllProductsStyle from './AllProducts'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { Empty } from 'antd';

const AllProducts = () => {
	const navigation = useNavigate()
	const errorRef = useRef()

	const [isLoading, setIsLoading] = useState(false)
	const [clientName, setClientName] = useState('')
	const [clientPhone, setClientPhone] = useState('')
	const [clientCode, setClientCode] = useState('')
	const [product, setProduct] = useState('')
	const [data, setData] = useState({
		data: [],
		currentPage: 0, //20
		nextPage: 0, //30
		oldPage: 0,
	})

	const search = (currentPage = 0, oldDataPage = 0) => {
		setIsLoading(true)
		api
			.post(
				`QueryService_PostQuery`,
				{
					QueryPath:
						'$crossjoin(Invoices,Invoices/DocumentLines,BusinessPartners)',
					QueryOption: `$expand=Invoices($select=DocEntry, DocNum, DocDate, CardName, CardCode, U_Owner, U_Guarantor, U_IMEI_PURCH),Invoices/DocumentLines($select=ItemDescription, LineTotal)&$filter=Invoices/DocEntry eq Invoices/DocumentLines/DocEntry and Invoices/CardCode eq BusinessPartners/CardCode and Invoices/Cancelled eq 'tNO'
        ${
					clientPhone.length <= 0
						? ''
						: `and contains(BusinessPartners/U_Telephone, '${clientPhone}')`
				} ${
					product.length <= 0
						? ''
						: `and contains(Invoices/DocumentLines/ItemDescription, '${product}')`
				} ${
					clientName.length <= 0
						? ''
						: `and contains(CardName, '${clientName}')`
				}  ${
					clientCode.length <= 0 ? '' : `and CardCode eq '${clientCode}'`
				}&$orderby=Invoices/DocDate desc&$skip=${currentPage}`,
				},
				{
					headers: {
						Prefer: 'odata.maxpagesize=10',
					},
				},
			)
			.then((res) => {
				const resData = get(JSON.parse(res.data), 'value', [])
				const nextPage = Number(
					get(JSON.parse(res.data), '@odata.nextLink', 'skip=0').split(
						'skip=',
					)[1],
				)

				if (oldDataPage === 0) {
					setData({
						data: [...resData],
						currentPage: currentPage === 0 ? 0 : data.nextPage,
						nextPage: nextPage === 0 ? data.currentPage : nextPage,
						oldPage: nextPage === 0 ? data.currentPage : nextPage - 20,
					})
				} else {
					setData({
						data: [...resData],
						currentPage: data.nextPage - 20,
						nextPage: nextPage,
						oldPage: nextPage < data.currentPage ? nextPage : nextPage - 20,
					})
				}
				setIsLoading(false)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		search(0, 0)
	}, [])

	const viewItem = (v) => {
		const url = `/viewItem?data=${encodeURIComponent(JSON.stringify(v))}&DocEntry=${v.DocEntry}`
		window.open(url, '_blank')
	}

	const newDatas = () => {
		if (data.nextPage > data.currentPage) {
			search(data.nextPage, 0)
		} else {
			alert('boshqa malumot yoq')
		}
	}

	const oldData = () => {
		if (data.oldPage < 0) {
			alert('boshqa malumot yoq')
		} else {
			search(data.oldPage, 1)
		}
	}

	return (
		<Layout>
			<AllProductsStyle>
				<div className="container">
					<p className="productTitle">Продажи</p>
					<div className="grid sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-5 gap-4 my-8">
						<input
							className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
							type="text"
							placeholder="Имя Клиента"
							defaultValue={clientName}
							onChange={(v) => setClientName(v.target.value)}
						/>
						<input
							className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
							type="text"
							placeholder="Телефон клиента"
							defaultValue={clientPhone}
							onChange={(v) => setClientPhone(v.target.value)}
						/>
						<input
							className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
							type="text"
							placeholder="Код клиента"
							defaultValue={clientCode}
							onChange={(v) => setClientCode(v.target.value)}
						/>
						<input
							className={'border-[1px] border-[#d9d9d9"] p-2 rounded-md '}
							type="text"
							placeholder="Товар"
							defaultValue={product}
							onChange={(v) => setProduct(v.target.value)}
						/>
						<Button onClick={() => search(0, 0)} isLoading={isLoading}>
							Поиск
						</Button>
					</div>

					{isLoading ? (
						<ClipLoader
							loading={true}
							size={75}
							aria-label="Loading Spinner"
							className={'loader'}
							data-testid="loader"
						/>
					) : (
						<>
							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												Код клиента
											</th>
											<th scope="col" className="py-4">
												Имя Клиента
											</th>
											<th scope="col" className="py-4">
												Кому принадлежит телефон
											</th>
											<th scope="col" className="py-4">
												Гарант
											</th>
											<th scope="col" className="py-4">
												IMEI
											</th>
											<th scope="col" className="px-6 py-4">
												Товар
											</th>
											<th scope="col" className="px-6 py-4">
												Деньги
											</th>
											<th scope="col" className="px-6 py-4">
												Дата продажи
											</th>
										</tr>
									</thead>
									<tbody>
										{data.data.length > 0 ? (
											data.data.map((v, i) => (
												<tr
													key={i}
													onClick={() => viewItem(v)}
													className="bg-white border-b  hover:bg-gray-50 "
												>
													<td
														scope="row"
														className="px-6 py-4 font-medium text-gray-900 "
													>
														{get(v, 'Invoices.CardCode', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Invoices.CardName', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Invoices.U_Owner', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Invoices.U_Garant', '-')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Invoices.U_IMEI_PURCH', '-')}
													</td>
													<td className="px-6 py-4">
														{get(
															v,
															'Invoices/DocumentLines.ItemDescription',
															'-',
														)}
													</td>
													<td className="px-6 py-4">
														{' '}
														{new Intl.NumberFormat('fr-FR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})
															.format(
																get(v, 'Invoices/DocumentLines.LineTotal', '-'),
															)
															.replace(',', '.')}
													</td>
													<td className="px-6 py-4">
														{get(v, 'Invoices.DocDate', '-')}
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="10">
													<Empty
														description="Нет информации!"
														image={Empty.PRESENTED_IMAGE_SIMPLE}
													/>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="topCard">
								<div className="flex">
									<Button
										className={'btn'}
										onClick={oldData}
										btnStyle={{ marginRight: 10 }}
									>
										{'<'}
									</Button>
									<Button className={'btn'} onClick={newDatas}>
										{'>'}
									</Button>
								</div>
								<Button
									className={'btn'}
									onClick={() => window.open('/createProducts', '_blank')}
								>
									{'+'}
								</Button>
							</div>
						</>
					)}
				</div>
			</AllProductsStyle>
			<>
				<ErrorModal
					getRef={(r) => {
						errorRef.current = r
					}}
				/>
			</>
		</Layout>
	)
}

export default AllProducts
