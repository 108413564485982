import { Settings } from './Settings'
import Layout from '../../components/Layout'
import React, { useEffect, useRef } from 'react'
import api from '../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import { ErrorModal, SuccessModal } from '../../components/Modal'
import { get } from 'lodash'
import { Empty } from 'antd';

const SettingsPage = () => {
	const [toggleActive, setToggleActive] = React.useState(1) // 1 - Месяц, 2 - Месяц B2B
	const [data, setData] = React.useState([])
	const [loading, setLoading] = React.useState(false)
	const [action, setAction] = React.useState(null)
	const [modal, setModal] = React.useState(false)
	const successModalRef = useRef()
	const errorRef = useRef()
	const [actionLoading, setActionLoading] = React.useState({ loading: false })
	const [submit, setSubmit] = React.useState({})
	const [code, setCode] = React.useState(null)
	const [changingData, setChangingData] = React.useState({})
	const [deleteLoading, setDeleteLoading] = React.useState(false)
	const [U_Months, setU_Months] = React.useState(null)
	const [U_Percent, setU_Percent] = React.useState(null)

	const handleToggle = (value) => {
		setToggleActive(value)
	}

	const handleCreate = () => {
		setAction('create')
		setModal(true)
	}

	const handleEdit = (code, item) => {
		setAction('edit')
		setModal(true)
		setCode(code)
		setChangingData({ ...item })
	}
	const deleteApi = async (code) => {
		if (toggleActive === 1) {
			setDeleteLoading(true)
			await api
				.delete(`U_INSTCONFBASIC(${code})`)
				.then(() => {
					setModal(false)
					successModalRef.current?.open('Удален успешно')
				})
				.then(() => {
					fetchData()
				})
				.catch((err) => {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				})
				.finally(() => {
					setCode(null)
					setDeleteLoading(false)
				})
		} else {
			try {
				setDeleteLoading(true)
				api
					.delete(`U_INSTCONFVIP(${code})`)
					.then(() => {
						setModal(false)
						successModalRef.current?.open('Удален успешно')
					})
					.then(() => {
						fetchData()
					})
			} catch (err) {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			} finally {
				setDeleteLoading(false)
				setCode(null)
			}
		}
	}

	const handleDelete = async (code) => {
		setAction('delete')
		setCode(code)
		deleteApi(code)
	}

	const fetchData = async () => {
		try {
			setLoading(true)
			const res =
				toggleActive === 1
					? await api.get(`U_INSTCONFBASIC`)
					: await api.get(`U_INSTCONFVIP`)
			setData(JSON.parse(res.data).value)
		} catch (err) {
			errorRef.current?.open(
				get(JSON.parse(err.response.data), 'error.message', ''),
			)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [toggleActive])

	const handleSubmit = async (e) => {
		e.preventDefault()

		// Access form data from the event object
		const formData = new FormData(e.target)

		// Read values using the name attributes
		setU_Months(formData.get('U_Months'))
		setU_Percent(formData.get('U_Percent'))

		setActionLoading({ loading: true })
		setSubmit({})
	}

	useEffect(() => {
		edit_createApi()
	}, [submit])

	async function edit_createApi() {
		if (action === 'create') {
			if (toggleActive === 1) {
				try {
					api
						.post(`U_INSTCONFBASIC`, {
							U_Months,
							U_Percent,
						})
						.then(() => {
							handleModalSuccess()
							successModalRef.current?.open('Успешно создано')
							fetchData()
						})
				} catch (err) {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				} finally {
					setActionLoading({ loading: false })
				}
			} else {
				try {
					api
						.post(`U_INSTCONFVIP`, {
							U_Months,
							U_Percent,
						})
						.then(() => {
							handleModalSuccess()
							successModalRef.current?.open('Успешно создано')
							fetchData()
						})
				} catch (err) {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				} finally {
					setActionLoading({ loading: false })
				}
			}
		} else if (action === 'edit') {
			if (toggleActive === 1) {
				try {
					api
						.patch(`U_INSTCONFBASIC(${code})`, {
							U_Months,
							U_Percent,
						})
						.then(() => {
							handleModalSuccess()
							successModalRef.current?.open('Изменено успешно')
							fetchData()
						})
				} catch (err) {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				} finally {
					setCode(null)
					setActionLoading({ loading: false })
				}
			} else {
				try {
					api
						.patch(`U_INSTCONFVIP(${code})`, {
							U_Months,
							U_Percent,
						})
						.then(() => {
							handleModalSuccess()
							successModalRef.current?.open('Изменено успешно')
							fetchData()
						})
				} catch (err) {
					errorRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				} finally {
					setActionLoading({ loading: false })
					setCode(null)
					setChangingData({})
				}
			}
		}
	}

	function handleModalSuccess() {
		setModal(false)
		setChangingData({})
	}

	return (
		<Layout>
			<Settings>
				<div className="title-box">
					<h2 className="topTitle text-xl font-bold text-center">
						Кредит калькулятор
					</h2>
				</div>
				{loading ? (
					<ClipLoader
						loading={true}
						size={75}
						aria-label="Loading Spinner"
						className={'loader'}
						data-testid="loader"
					/>
				) : (
					<div className="container">
						<div className="toggle">
							<div
								className={toggleActive === 1 ? 'toggle-active' : null}
								onClick={() => handleToggle(1)}
							>
								Месяц
							</div>
							{/* <div
								className={toggleActive === 2 ? 'toggle-active' : null}
								onClick={() => handleToggle(2)}
							>
								Месяц B2B
							</div> */}
						</div>
						<div>
							<button className="create-btn" onClick={handleCreate}>
								Добавить
							</button>
							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												№
											</th>
											<th scope="col" className="px-6 py-4">
												Месяц
											</th>
											<th scope="col" className="px-6 py-4">
												Процент
											</th>
											<th scope="col" className="px-6 py-4">
												Действия
											</th>
										</tr>
									</thead>
									<tbody>
										{data.length > 0 ? (
											data.map((item, index) => (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={item.Code}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{index + 1}
													</td>
													<td className="px-6 py-4">{item.U_Months}</td>
													<td className="px-6 py-4">{item.U_Percent}%</td>
													<td className="px-6 py-4">
														<button
															className="edit-btn"
															onClick={() => handleEdit(item.Code, item)}
														>
															Редактировать
														</button>
														<button
															className="delete-btn"
															disabled={code === item.Code && deleteLoading}
															onClick={() => handleDelete(item.Code)}
														>
															{code === item.Code && deleteLoading && (
																<ClipLoader
																	loading={true}
																	size={10}
																	aria-label="Loading Spinner"
																	data-testid="loader"
																/>
															)}
															Удалить
														</button>
													</td>
												</tr>
											))
										) : (
											<tr >
												<td colSpan="10" >
													<Empty description="Нет информации!" image={Empty.PRESENTED_IMAGE_SIMPLE} />
												</td>
											</tr>											)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
				{modal && (
					<div className="modal">
						<div className="modal-self">
							<div className="exit" onClick={() => handleModalSuccess()}>
								X
							</div>
							<div className="modal-content">
								<h2 className="text-lg text-center font-bold">
									{action === 'create'
										? 'Добавить'
										: action === 'edit'
											? 'Редактировать'
											: null}
								</h2>
								<form onSubmit={handleSubmit}>
									<div className="field">
										<label className={'font-medium text-zinc-600 text-sm mb-1'}>
											Месяц
										</label>
										<input
											className={
												'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
											}
											defaultValue={changingData.U_Months}
											type="number"
											placeholder="Месяц"
											name={'U_Months'}
										/>
									</div>
									<div className="field">
										<label className={'font-medium text-zinc-600 text-sm mb-1'}>
											Процент
										</label>
										<input
											className={
												'border-[1px] border-[#DFE2E9"] p-1 rounded-md w-full'
											}
											defaultValue={changingData.U_Percent}
											type="number"
											placeholder="Процент"
											name={'U_Percent'}
										/>
									</div>
									<button disabled={actionLoading.loading}>
										{actionLoading.loading && (
											<ClipLoader
												loading={true}
												size={10}
												aria-label="Loading Spinner"
												data-testid="loader"
											/>
										)}
										{action === 'create'
											? 'Создать'
											: action === 'edit'
												? 'Сохранить'
												: null}
									</button>
								</form>
							</div>
						</div>
					</div>
				)}
				<>
					<ErrorModal getRef={(r) => (errorRef.current = r)} />
					<SuccessModal
						getRef={(r) => (successModalRef.current = r)}
						onConfirm={() => successModalRef.current?.close()}
					/>
				</>
			</Settings>
		</Layout>
	)
}

export default SettingsPage
