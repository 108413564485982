import React, { useEffect, useRef, useState } from 'react'
import DashboardStyle from './Dashboard'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import api, { customFuncsApi } from '../../api'
import { get } from 'lodash'
import Layout from '../../components/Layout'
import { ErrorModal } from '../../components/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment'
import { useSelector } from 'react-redux'
import customMainApi from '../../api/index'
import dayjs from 'dayjs'

import { Space } from 'antd'
import Tab1 from './Tab1/index'
import Tab2 from './Tab2/index';

const Dashboard = () => {
	const navigation = useNavigate()
	const { getMe } = useSelector((state) => state.main)
	const dateFormat = 'YYYY-MM-DD'

	const [activeTab, setActiveTab] = useState('tab1')

	const renderContent = () => {
		switch (activeTab) {
			case 'tab1':
				return <Tab1 />
			case 'tab2':
				return <Tab2 />
			// case 'tab3':
			// 	return (
			// 		<>
			// 			<Tab3 />
			// 		</>
			// 	)

			default:
				return null
		}
	}

	return (
		<Layout>
			<div className="m-5">
				<h1 className="text-2xl font-bold">Dashboard</h1>
				<Space style={{ marginBottom: 16 }} className="mt-10">
					<button
						className={`${
							activeTab === 'tab1'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab1')}
					>
						Главная страница
					</button>
					<button
						className={`${
							activeTab === 'tab2'
								? 'text-blue-900 underline underline-offset-8'
								: ''
						} font-semibold `}
						onClick={() => setActiveTab('tab2')}
					>
						Отчеты
					</button>
					{/* <button
							className={`${
								activeTab === 'tab2'
									? 'text-blue-900 underline underline-offset-8'
									: ''
							} font-semibold ml-3`}
							onClick={() => setActiveTab('tab2')}
						>
							Moliyaviy ko'rsatkichlar
						</button>
						<button
							className={`${
								activeTab === 'tab3'
									? 'text-blue-900 underline underline-offset-8'
									: ''
							} font-semibold ml-3`}
							onClick={() => setActiveTab('tab3')}
						>
							Moliyaviy hisobotlar
						</button> */}
				</Space>
				<div className="mt-3">{renderContent()}</div>
			</div>
		</Layout>
	)
}

export default Dashboard
