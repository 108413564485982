import { useState, useEffect } from 'react'

const CheckboxGroup = ({
	items,
	selectedItems,
	handleSelectSerialNumber,
	handleRemoveSerialNumber,
	quantity,
}) => {
	// Function to check if we can select more IMEIs
	const canSelectMore = () => selectedItems.length < quantity

	const handleSelectAll = (e) => {
		const newSelectedValues = e.target.checked
			? items
					.slice(0, quantity) // limit the selection to the quantity
					.map((item) => ({
						imei: item.IntrSerial,
						sysNumber: item.SysNumber,
					}))
			: []

		if (newSelectedValues.length > 0) {
			newSelectedValues
				.filter(({ imei }) => !selectedItems.some((item) => item.imei === imei))
				.forEach(({ imei, sysNumber }) => {
					handleSelectSerialNumber(imei, sysNumber)
				})
		} else {
			items
				.filter((item) =>
					selectedItems.some((si) => si.imei === item.IntrSerial),
				)
				.forEach((item) => handleRemoveSerialNumber(item.IntrSerial))
		}
	}

	const handleCheckboxChange = (e) => {
		const value = e.target.value
		const isChecked = e.target.checked
		const item = items.find((i) => i.IntrSerial === value)

		if (isChecked) {
			if (canSelectMore()) {
				handleSelectSerialNumber(item.IntrSerial, item.SysNumber)
			}
		} else {
			handleRemoveSerialNumber(item.IntrSerial)
		}
	}

	return (
		<div>
			{items?.length === 0 && <p>Нет IMEI</p>}
			{items?.length > 0 && (
				<div>
					<label className={'flex gap-2'}>
						<input
							type="checkbox"
							checked={items?.length === selectedItems.length}
							onChange={handleSelectAll}
							disabled={!canSelectMore()} // Disable Select All if max quantity is reached
						/>
						Выбрать все (max {quantity})
					</label>
					<div className={'flex flex-col gap-1 mt-2 pl-3'}>
						{items?.map((item, index) =>
							item.IntrSerial !== null ? (
								<label key={index} className={'flex gap-2'}>
									<input
										type="checkbox"
										value={item.IntrSerial}
										checked={selectedItems.some(
											(si) => si.imei === item.IntrSerial,
										)}
										onChange={handleCheckboxChange}
										disabled={
											!canSelectMore() &&
											!selectedItems.some((si) => si.imei === item.IntrSerial)
										} // Disable checkbox if max quantity is reached
									/>
									{item.IntrSerial}
								</label>
							) : (
								''
							),
						)}
					</div>
					{/* {selectedItems.length !== quantity ? (
						<p className="text-red-500">
							Please select exactly {quantity} IMEIs.
						</p>
					) : (
						''
					)} */}
				</div>
			)}
		</div>
	)
}

export default CheckboxGroup
