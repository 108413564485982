import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from './CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import { customFuncsApi } from '../../../api'
import moment from 'moment'
import customMainApi from '../../../api/index'
import formatPhoneNumber from '../../../helpers/PhoneWithSpaces'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const CheckByPhoneModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	phones = '',
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [data, setData] = useState([])
	const [userFields, setUserFields] = useState([])

	const errorModalRef = useRef()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				getInvoceByPhoneNumber(t)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	useEffect(() => {
		userFieldsFn()
	}, [])

	const getInvoceByPhoneNumber = (phones) => {
		const phone = phones.split(',')
		console.log(phone)
		const formattedPhones = phone.map((p) => `"${p.trim()}"`).join(',')

		try {
			customFuncsApi
				.get(`xsSql/getInvoicesByPhoneNumbers?phones=[${formattedPhones}]`)
				.then((res) => {
					const resData = get(res, 'data.value', [])
					console.log(resData)
					setData(resData)
				})
		} catch (err) {
			console.log(err)
			errorModalRef.current?.open(JSON.parse(err.response.data).error.message)
		}
	}

	const userFieldsFn = () => {
		customMainApi
			.get('UserFieldsMD', {
				params: {
					$filter: "TableName eq 'OINV'",
					$select: 'Name,ValidValuesMD,TableName',
				},
			})
			.then((res) => {
				setUserFields(JSON.parse(res.data).value)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				<div className=" w-full overflow-y-auto overflow-x-auto ">
					{data.length > 0 ? (
						<table className="w-full  text-sm text-left rtl:text-right text-gray-500 ">
							<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
								<tr>
									<th scope="col" className="px-12 py-4">
										Имя клиента
									</th>
									<th scope="col" className="px-12 py-4">
										Товар
									</th>
									<th scope="col" className="px-6 py-4">
										Статус
									</th>
									<th scope="col" className="px-6 py-4">
										Телефон
									</th>

									<th scope="col" className="px-6 py-4">
										Общая сума
									</th>
									<th scope="col" className="px-6 py-4">
										Общий долг
									</th>

									<th scope="col" className="px-6 py-4">
										Дата продажи
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((v, i) => {
									return (
										<tr
											key={i}
											// onClick={() => viewItem(v)}
											className="bg-white border-b  hover:bg-gray-50 "
										>
											{' '}
											<td className="px-6 py-4">{get(v, 'CardName', '')}</td>
											<td className="px-6 py-4">{get(v, 'Dscription', '')}</td>
											<td className="px-6 py-4">
												{get(v, 'U_Score', '') !== ''
													? userFields
															?.find((item) => item.Name === 'Score')
															?.ValidValuesMD.find(
																(value) =>
																	get(v, 'U_Score', '') === value.Value,
															)?.Description || ''
													: ''}
											</td>
											<td className="px-6 py-4">
												{formatPhoneNumber(get(v, 'U_Telephone', '-'))}
											</td>
											<td className="px-6 py-4">
												{new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(get(v, 'DocTotal', '-'))
													.replace(',', '.')}
											</td>
											<td className="px-6 py-4">
												{new Intl.NumberFormat('fr-FR', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												})
													.format(
														get(v, 'DocTotal', '-') - get(v, 'PaidToDate', '-'),
													)
													.replace(',', '.')}
											</td>
											<td className="px-6 py-4">
												{moment(get(v, 'DocDate', '-')).format('DD-MM-YYYY')}
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					) : (
						''
					)}
				</div>
				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(CheckByPhoneModal)
