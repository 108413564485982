import React, { useState } from 'react'
import DrawerMenu from '../DrawerMenu'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const Layout = ({ children }) => {
	const { getMe } = useSelector((state) => state.main)
	const navigate = useNavigate()

	const [clicked, setClicked] = useState(false)

	const handleClick = () => {
		navigate('/login')
	}

	console.log(getMe)
	return (
		<div className="">
			<DrawerMenu />

			<div
				className="h-screen w-full overflow-y-auto"
				onClick={() => {
					if (clicked) setClicked(false)
				}}
			>
				<div className="relative flex h-[80px] w-full items-center justify-end gap-4 bg-[#FFFFFF] pr-10 drop-shadow-xl">
					<div
						className="flex items-center gap-3"
						onClick={(event) => {
							event.stopPropagation() // Prevents the click event from reaching the parent div
							setClicked(!clicked)
						}}
					>
						<div className="ml-10 flex h-8 w-8 items-center justify-center rounded-full bg-[#0085FF1A] text-[#0085FF]">
							{get(getMe, 'FirstName', '')[0]}
						</div>
						<h1 className="font-inter text-base font-medium">
							{get(getMe, 'FirstName', '') + ' ' + get(getMe, 'LastName', '')}
						</h1>
						{clicked && (
							<div className="absolute right-3 top-[70px] mt-4 w-full max-w-60 rounded bg-[#FFFFFF] p-4 drop-shadow-xl">
								<div className="flex flex-col gap-2">
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Доллар касса</span>
										<span>{get(getMe, 'U_CashAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Доллар карта</span>
										<span>{get(getMe, 'U_CardUsdAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Карта</span>
										<span>{get(getMe, 'U_CardAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Сум касса</span>
										<span>{get(getMe, 'U_CashUzsAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Терминал</span>
										<span>{get(getMe, 'U_TerminalAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Трансфер касса</span>
										<span>{get(getMe, 'U_TransAccount', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Склад</span>
										<span>{get(getMe, 'U_Warehouse', '')}</span>
									</div>
									<div className="flex items-center justify-between text-gray-800">
										<span className="text-gray-500">Должность</span>
										<span>{get(getMe, 'JobTitle', '')}</span>
									</div>
								</div>
								<button
									className="mt-4 flex w-full items-center justify-center gap-2 rounded bg-red-500 p-2 text-white"
									onClick={handleClick}
								>
									Выход <FaSignOutAlt />
								</button>
							</div>
						)}
					</div>
				</div>
				{children}
			</div>
		</div>
	)
}

export default Layout
