import React, { memo, useEffect, useState, useRef } from 'react'
import CheckByPhoneModalStyle from '../CheckByPhoneModal/CheckByPhoneModalStyle'
import Modal from 'react-modal'
import { ErrorModal } from '../index'
import { get } from 'lodash'
import api from '../../../api'
import ClipLoader from 'react-spinners/ClipLoader'
import ImeiModal from '../InventoryTansfersModal/ImeiModal'

import moment from 'moment'
import customMainApi from '../../../api/index'
import { Empty } from 'antd'
const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 15,
		border: 'none',
		maxHeight: '100vh',
		width: '85%',
	},
	overlay: {
		background: '#00000099',
	},
}

const OutgoingPaymentsModal = ({
	getRef = () => {},
	onConfirm = () => {},
	onClose = () => {},
	phones = '',
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState([])
	const [userFields, setUserFields] = useState([])
	const [creator, setCreator] = useState('')

	const imeiModalRef = useRef()
	const errorModalRef = useRef()

	useEffect(() => {
		const ref = {
			open: (t) => {
				setIsOpenModal(true)
				search(t.startDate, t.endDate)
			},
			close: () => setIsOpenModal(false),
		}
		getRef(ref)
	}, [])
	const search = (startDate, endDate) => {
		setIsLoading(true)
		api
			.get(
				`/VendorPayments?%24select=DocEntry,DocNum,DocType,CardCode,CardName,CashAccount,DocCurrency,CashSum,ControlAccount,DocDate,CashSumFC&$filter=DocDate ge '${startDate + '00:00:00.000Z'}' and DocDate le '${endDate + '00:00:00.000Z'}'`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=1000',
					},
				},
			)
			.then((res) => {
				const resData = JSON.parse(res.data).value
				setData(resData)
				setIsLoading(false)
			})
			.catch((err) => {
				if (err)
					errorModalRef.current?.open(
						get(JSON.parse(err.response.data), 'error.message', ''),
					)
				setIsLoading(false)
			})
	}

	return (
		<Modal
			isOpen={isOpenModal}
			onRequestClose={() => setIsOpenModal(false)}
			style={customStyles}
			contentLabel="Example Modal"
			ariaHideApp={false}
		>
			<CheckByPhoneModalStyle>
				{isLoading ? (
					<ClipLoader
						loading={true}
						size={75}
						aria-label="Loading Spinner"
						className={'loader'}
						data-testid="loader"
					/>
				) : (
					<>
						<div className="overflow-y-auto mb-8">
							<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
									<tr>
										<th scope="col" className="px-6 py-4">
											Номер документа
										</th>
										<th scope="col" className="px-6 py-4">
											Поставщик
										</th>
										<th scope="col" className="px-6 py-4">
											С Счета
										</th>
										<th scope="col" className="px-6 py-4">
											Валюта
										</th>
										<th scope="col" className="px-6 py-4">
											Сумма
										</th>
										<th scope="col" className="px-6 py-4">
											На Счет
										</th>
										<th scope="col" className="px-6 py-4">
											Дата
										</th>
									</tr>
								</thead>
								<tbody>
									{data.length > 0 ? (
										data.map((v, i) => (
											<tr
												className="bg-white border-b  hover:bg-gray-50 "
												key={i}
											>
												<td className="px-6 py-4 font-medium text-gray-900 ">
													{get(v, 'DocNum', '')}
												</td>
												<td className="px-6 py-4 whitespace-nowrap">
													{get(v, 'CardName', '')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'CashAccount', '')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'DocCurrency', '')}
												</td>
												<td className="px-6 py-4">
													{get(v, 'DocCurrency', '') !== 'UZS'
														? new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'CashSum', ''))
																.replace(',', '.')
														: new Intl.NumberFormat('fr-FR', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
															})
																.format(get(v, 'CashSumFC', ''))
																.replace(',', '.')}
												</td>
												<td className="px-6 py-4">{get(v, 'CardCode', '')}</td>
												<td className="px-6 py-4">
													{moment(get(v, 'DocDate', '')).format('DD-MM-YYYY')}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="10">
												<Empty
													description="Нет информации!"
													image={Empty.PRESENTED_IMAGE_SIMPLE}
												/>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						{/* <div className="topCard">
							<div style={{ alignItems: 'center' }} className="flex">
								<Button onClick={oldData} btnStyle={{ marginRight: 10 }}>
									{'<'}
								</Button>
								<p style={{ marginRight: '10px' }}>{pagination.page + 1}</p>
								<Button onClick={newDatas}>{'>'}</Button>
							</div>
							<div className="btn-box" style={{ display: 'flex', gap: '15px' }}>
								<Button
									btnStyle={{ width: 'max-content', padding: '0 10px' }}
									onClick={() => window.open('/pay-supplier', '_blank')}
								>
									Оплата поставщику
								</Button>
								<Button
									btnStyle={{ width: 'max-content', padding: '0 10px' }}
									onClick={() => window.open('/constant-consumption', '_blank')}
								>
									Постоянные расходы
								</Button>
							</div>
						</div> */}
					</>
				)}

				<ErrorModal getRef={(r) => (errorModalRef.current = r)} />
				<ImeiModal getRef={(r) => (imeiModalRef.current = r)} />
			</CheckByPhoneModalStyle>
		</Modal>
	)
}

export default memo(OutgoingPaymentsModal)
